import { createSlice, createDraftSafeSelector } from '@reduxjs/toolkit';
import i18next from 'i18next';

const initialState = {
  langCd: localStorage.getItem('langCd') || 'ko',
};

const reducers = {
  changeLangCd: (state, { payload: langCd }) => {
    i18next.changeLanguage(langCd);
    localStorage.setItem('langCd', langCd);
    state.langCd = langCd;
  },
};

const slice = createSlice({
  name: 'config',
  initialState: initialState,
  reducers: reducers,
});

const selectLangCd = createDraftSafeSelector(
  state => state.langCd,
  langCd => langCd,
);

export const configSelector = {
  langCd: state => selectLangCd(state[slice.name]),
};

export const CONFIG = slice.name;
export const configReducer = slice.reducer;
export const configAction = slice.actions;
