import { managerBaseApi } from '../managerBaseApi';
import { editorAction } from './editorSlice';

const overlayApi = managerBaseApi.injectEndpoints({
  endpoints: build => ({
    overlayList: build.query({
      query: data => ({ url: '/interaction/overlay/list', data }),
      transformResponse: response => {
        if (!response.resultFlag) {
          return [];
        }
        const overlayList = response.overlayList;
        return overlayList;
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ overlayId }) => ({ type: 'OVERLAY', id: overlayId })),
        { type: 'OVERLAY', id: 'LIST' },
      ],
    }),
    overlayAdd: build.mutation({
      query: data => ({ url: '/interaction/overlay/add', data }),
      invalidatesTags: (result, error, payload) => [{ type: 'OVERLAY', id: 'LIST' }],
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        let patchResult = undefined;
        try {
          const { data } = await queryFulfilled;
          if (data.resultFlag) {
            const overlayInfo = data.overlayInfo;
            if (overlayInfo) {
              patchResult = dispatch(
                overlayApi.util.updateQueryData('overlayList', { playlistId: overlayInfo.playlistId }, draft => {
                  const index = draft.findIndex(overlay => overlay.overlayId === overlayInfo.overlayId);
                  draft[index] = { ...draft[index], ...overlayInfo };
                }),
              );
            }
          }
        } catch {
          patchResult && patchResult.undo();
        }
      },
    }),
    overlayUpdateList: build.mutation({
      query: data => ({ url: '/interaction/overlay/update-list', data }),
      invalidatesTags: [{ type: 'OVERLAY', id: 'LIST' }],
      async onQueryStarted({ updateList }, { dispatch, queryFulfilled, getState }) {
        const playlistId = getState().editor.selectPlaylistId || '';

        const patchResultList = [];
        for (const update of updateList) {
          const { overlayId, updateInfo } = update;
          patchResultList.push(
            dispatch(
              overlayApi.util.updateQueryData('overlayList', { playlistId }, draft => {
                const index = draft.findIndex(overlay => overlay.overlayId === overlayId);
                draft[index] = { ...draft[index], ...updateInfo };
              }),
            ),
          );
        }

        try {
          await queryFulfilled;
        } catch {
          patchResultList.forEach(patchResult => patchResult.undo());
        }
      },
    }),
    overlayRemoveList: build.mutation({
      async queryFn({ removeList }, { dispatch, getState }, extraOptions, fetchBaseApi) {
        let patchResult = undefined;
        try {
          const selectLayerList = getState().editor.selectLayerList || [];
          let fileterSelectLayerList = selectLayerList;
          for (const removeItem of removeList) {
            fileterSelectLayerList = fileterSelectLayerList.filter(layer => layer.id !== removeItem.overlayId);
          }
          dispatch(editorAction.setState({ key: 'selectLayerList', value: fileterSelectLayerList }));

          const result = await fetchBaseApi({ url: '/interaction/overlay/remove-list', data: { removeList } });
          return result;
        } catch (error) {
          patchResult && patchResult.undo();
        }
      },
      invalidatesTags: [{ type: 'OVERLAY', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  endpoints,
  useOverlayListQuery,
  useOverlayAddMutation,
  useOverlayUpdateListMutation,
  useOverlayRemoveListMutation,
} = overlayApi;
