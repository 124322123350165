import { managerBaseApi } from '../managerBaseApi';

const layerContentsApi = managerBaseApi.injectEndpoints({
  endpoints: build => ({
    layerContentsList: build.query({
      query: data => ({ url: '/interaction/layer-contents/list', data }),
      transformResponse: response => {
        if (!response.resultFlag) {
          return [];
        }

        const layerContentsList = response.layerContentsList;
        return layerContentsList;
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ contentsId }) => ({ type: 'LAYER-CONTENTS', id: contentsId })),
        { type: 'LAYER-CONTENTS', id: 'LIST' },
      ],
    }),
    layerContentsAdd: build.mutation({
      query: data => ({ url: '/interaction/layer-contents/add', data }),
      invalidatesTags: [{ type: 'LAYER-CONTENTS', id: 'LIST' }],
      async onQueryStarted(payload, { dispatch, queryFulfilled, getState }) {
        let patchResult = undefined;
        try {
          const { data } = await queryFulfilled;
          if (data.resultFlag) {
            const contentsInfo = data.contentsInfo;
            if (contentsInfo) {
              patchResult = dispatch(
                layerContentsApi.util.updateQueryData('layerContentsList', { layerId: contentsInfo.layerId }, draft => {
                  const index = draft.findIndex(contents => contents.contentsId === contentsInfo.contentsId);
                  draft[index] = { ...draft[index], ...contentsInfo };
                }),
              );
            }
          }
        } catch {
          patchResult && patchResult.undo();
        }
      },
    }),
    layerContentsUpdate: build.mutation({
      query: data => ({ url: '/interaction/layer-contents/update', data }),
      invalidatesTags: [{ type: 'LAYER-CONTENTS', id: 'LIST' }],
      async onQueryStarted({ contentsId, contentsData, contentsFileList }, { dispatch, queryFulfilled, getState }) {
        let patchResult = undefined;
        try {
          dispatch(
            layerContentsApi.util.updateQueryData('layerContentsList', { contentsId }, draft => {
              const index = draft.findIndex(contents => contents.contentsId === contentsId);
              draft[index] = { ...draft[index], contentsData, contentsFileList };
            }),
          );
        } catch (error) {
          patchResult && patchResult.undo();
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const { endpoints, useLayerContentsListQuery, useLayerContentsAddMutation, useLayerContentsUpdateMutation } =
  layerContentsApi;
