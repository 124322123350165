import { managerBaseApi } from '../managerBaseApi';

const frameApi = managerBaseApi.injectEndpoints({
  endpoints: build => ({
    frameList: build.query({
      query: data => ({ url: '/interaction/frame/list', data }),
      transformResponse: response => {
        if (!response.resultFlag) {
          return [];
        }
        const frameList = response.frameList;
        return frameList;
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ frameId }) => ({ type: 'FRAME', id: frameId })),
        { type: 'FRAME', id: 'LIST' },
      ],
    }),
    frameAdd: build.mutation({
      query: data => ({ url: '/interaction/frame/add', data }),
      invalidatesTags: [{ type: 'FRAME', id: 'LIST' }],
    }),
    frameAddByDesign: build.mutation({
      query: data => ({ url: '/interaction/frame/add-by-design', data }),
      invalidatesTags: [{ type: 'FRAME', id: 'LIST' }],
    }),
    frameEdit: build.mutation({
      query: data => ({ url: '/interaction/frame/edit', data }),
      invalidatesTags: (result, error, payload) => [{ type: 'FRAME', id: payload.frameId }],
    }),
    frameUpdateList: build.mutation({
      query: data => ({ url: '/interaction/frame/update-list', data }),
      invalidatesTags: [{ type: 'FRAME', id: 'LIST' }],
    }),
    frameRemove: build.mutation({
      query: data => ({ url: '/interaction/frame/remove', data }),
      invalidatesTags: [{ type: 'FRAME', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  endpoints,
  useFrameListQuery,
  useFrameAddMutation,
  useFrameAddByDesignMutation,
  useFrameEditMutation,
  useFrameUpdateListMutation,
  useFrameRemoveMutation,
} = frameApi;
