import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import HashLoader from 'react-spinners/HashLoader';

import { useTokenMutation } from 'rtk/core/authApi';

const Token = () => {
  const navigate = useNavigate();

  const [tokenTrigger] = useTokenMutation();

  useEffect(() => {
    (async () => {
      const { data, error } = await tokenTrigger();
      if (error) {
        navigate('/auth/login');
      } else if (data.loginType === 'account') {
      } else if (data.loginType === 'user') {
        sessionStorage.setItem('placeId', data.placeId);
      } else {
        navigate('/auth/login');
      }
    })().catch(err => null);
  }, [navigate, tokenTrigger]);

  return (
    <Container>
      <HashLoader
        css={{
          display: 'block',
          width: '100%',
          height: '100%',
          margin: '0 auto',
        }}
        color={'#41a1ea'}
        loading={true}
        size={100}
      />
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.grayBackground};
`;

export default React.memo(Token);
