import { managerBaseApi } from '../managerBaseApi';
import { editorAction } from './editorSlice';

const layerApi = managerBaseApi.injectEndpoints({
  endpoints: build => ({
    layerList: build.query({
      query: data => ({ url: '/interaction/layer/list', data }),
      transformResponse: (response, meta, arg) => {
        if (!response.resultFlag) {
          return [];
        }
        const layerList = response.layerList;
        return layerList;
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ layerId }) => ({ type: 'LAYER', id: layerId })),
        { type: 'LAYER', id: 'LIST' },
      ],
    }),
    layerAdd: build.mutation({
      query: data => ({ url: '/interaction/layer/add', data }),
      invalidatesTags: (result, error, payload) => [{ type: 'LAYER', id: 'LIST' }],
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        let patchResult = undefined;
        try {
          const { data } = await queryFulfilled;
          if (data.resultFlag) {
            const layerInfo = data.layerInfo;
            if (layerInfo) {
              patchResult = dispatch(
                layerApi.util.updateQueryData('layerList', { frameId: layerInfo.frameId }, draft => {
                  const index = draft.findIndex(layer => layer.layerId === layerInfo.layerId);
                  draft[index] = { ...draft[index], ...layerInfo };
                }),
              );
            }
          }
        } catch {
          patchResult && patchResult.undo();
        }
      },
    }),
    layerUpdateList: build.mutation({
      query: data => ({ url: '/interaction/layer/update-list', data }),
      invalidatesTags: [{ type: 'LAYER', id: 'LIST' }],
      async onQueryStarted({ updateList }, { dispatch, queryFulfilled, getState }) {
        const frameId = getState().editor.selectFrameId || '';

        const patchResultList = [];
        for (const update of updateList) {
          const { layerId, updateInfo } = update;
          patchResultList.push(
            dispatch(
              layerApi.util.updateQueryData('layerList', { frameId }, draft => {
                const index = draft.findIndex(layer => layer.layerId === layerId);
                draft[index] = { ...draft[index], ...updateInfo };
              }),
            ),
          );
        }

        try {
          await queryFulfilled;
        } catch {
          patchResultList.forEach(patchResult => patchResult.undo());
        }
      },
    }),
    layerRemoveList: build.mutation({
      async queryFn({ removeList }, { dispatch, getState }, extraOptions, fetchBaseApi) {
        let patchResult = undefined;
        try {
          const selectLayerList = getState().editor.selectLayerList || [];
          let fileterSelectLayerList = selectLayerList;
          for (const removeItem of removeList) {
            fileterSelectLayerList = fileterSelectLayerList.filter(layer => layer.id !== removeItem.layerId);
          }
          dispatch(editorAction.setState({ key: 'selectLayerList', value: fileterSelectLayerList }));

          const result = await fetchBaseApi({ url: '/interaction/layer/remove-list', data: { removeList } });
          return result;
        } catch (error) {
          patchResult && patchResult.undo();
        }
      },
      invalidatesTags: [{ type: 'LAYER', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  endpoints,
  useLayerListQuery,
  useLayerAddMutation,
  useLayerUpdateListMutation,
  useLayerRemoveListMutation,
} = layerApi;
