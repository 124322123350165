import { createSlice, createDraftSafeSelector } from '@reduxjs/toolkit';
import { endpoints } from './spaceApi';

const initialState = {
  spaceId: '',
  permissionType: '',
  spaceList: [],
  spaceInfo: {},
  memberList: [],
};

const reducers = {
  setState: (state, { payload: { key, value } }) => {
    state[key] = value;
  },
  setStateList: (state, { payload }) => {
    for (const item of payload) {
      state[item.key] = item.value;
    }
  },
};

const extraReducers = builder => {
  builder.addMatcher(endpoints.spaceList.matchFulfilled, (state, { payload }) => {
    state.spaceList = payload;
  });
  builder.addMatcher(endpoints.spaceMemberList.matchFulfilled, (state, { payload }) => {
    state.memberList = payload;
  });
};

const slice = createSlice({
  name: 'space',
  initialState: initialState,
  reducers: reducers,
  extraReducers: extraReducers,
});

const selectSpaceId = createDraftSafeSelector(
  state => state.spaceId,
  spaceId => spaceId,
);

const selectPermissionType = createDraftSafeSelector(
  state => state.permissionType,
  permissionType => permissionType,
);

const selectSpaceList = createDraftSafeSelector(
  state => state.spaceList,
  spaceList => spaceList,
);

const selectSpaceInfo = createDraftSafeSelector(
  state => state.spaceInfo,
  spaceInfo => spaceInfo,
);

const selectMemberList = createDraftSafeSelector(
  state => state.memberList,
  memberList => memberList,
);

export const spaceSelector = {
  spaceId: state => selectSpaceId(state[slice.name]),
  permissionType: state => selectPermissionType(state[slice.name]),
  spaceList: state => selectSpaceList(state[slice.name]),
  spaceInfo: state => selectSpaceInfo(state[slice.name]),
  memberList: state => selectMemberList(state[slice.name]),
};

export const SPACE = slice.name;
export const spaceReducer = slice.reducer;
export const spaceAction = slice.actions;
