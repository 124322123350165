import { managerBaseApi, fetchMultipartApi } from '../managerBaseApi';

export const {
  endpoints,
  usePlaceListQuery,
  usePlaceListByMemberQuery,
  usePlaceDetailQuery,
  usePlaceAddMutation,
  usePlaceEditMutation,
  usePlaceUpdateMutation,
  usePlaceRemoveMutation,
  usePlaceMemberListQuery,
  usePlaceMemberLastLoginListQuery,
  usePlaceMemberAddMutation,
  usePlaceMemberEditMutation,
  usePlaceMemberRemoveMutation,
} = managerBaseApi.injectEndpoints({
  endpoints: build => ({
    placeList: build.query({
      query: data => ({ url: '/core/place/list', data }),
      transformResponse: response => {
        const placeList = response.placeList;
        const sortList = [...placeList];
        sortList.sort((lhs, rhs) => {
          if (lhs.placeNm.toLowerCase() === rhs.placeNm.toLowerCase()) {
            return 0;
          }
          return lhs.placeNm.toLowerCase() > rhs.placeNm.toLowerCase() ? 1 : -1;
        });
        return sortList;
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ placeId }) => ({ type: 'PLACE', id: placeId })),
        { type: 'PLACE', id: 'LIST' },
      ],
    }),
    placeListByMember: build.query({
      query: data => ({ url: '/core/place/list-by-member', data }),
      transformResponse: response => {
        const placeList = response.placeList;
        const sortList = [...placeList];
        sortList.sort((lhs, rhs) => {
          if (lhs.placeNm.toLowerCase() === rhs.placeNm.toLowerCase()) {
            return 0;
          }
          return lhs.placeNm.toLowerCase() > rhs.placeNm.toLowerCase() ? 1 : -1;
        });
        return sortList;
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ placeId }) => ({ type: 'PLACE-BY-MEMBER', id: placeId })),
        { type: 'PLACE-BY-MEMBER', id: 'LIST' },
      ],
    }),
    placeDetail: build.query({
      query: data => ({ url: '/core/place/detail', data }),
      transformResponse: response => {
        const placeInfo = response.placeInfo;
        return placeInfo;
      },
      providesTags: (result, error, payload) => [{ type: 'PLACE-INFO', id: payload.placeId }],
    }),
    placeAdd: build.mutation({
      query: data => ({ url: '/core/place/add', data }),
      invalidatesTags: [{ type: 'PLACE', id: 'LIST' }],
    }),
    placeEdit: build.mutation({
      async queryFn(arg, { dispatch, getState }, extraOptions, baseQuery) {
        try {
          const uploadData = new FormData();
          uploadData.append('placeId', arg.placeId);
          uploadData.append('placeNm', arg.placeNm);
          arg.uploadFile && uploadData.append('uploadFile', arg.uploadFile, arg.uploadFile.name);
          const response = await fetchMultipartApi('/core/place/edit', uploadData);
          if (response.resultFlag) {
            return { data: response.placeInfo };
          } else {
            return { error: 'place edit fail.' };
          }
        } catch (error) {
          return { error: error.toString() };
        }
      },
      invalidatesTags: (result, error, payload) => [{ type: 'PLACE', id: payload.placeId }],
    }),
    placeUpdate: build.mutation({
      query: data => ({ url: '/core/place/update', data }),
      invalidatesTags: [{ type: 'PLACE', id: 'LIST' }],
    }),
    placeRemove: build.mutation({
      query: data => ({ url: '/core/place/remove', data }),
      invalidatesTags: [{ type: 'PLACE', id: 'LIST' }],
    }),
    placeMemberList: build.query({
      query: data => ({ url: '/core/place/member-list', data }),
      transformResponse: response => {
        const accountList = response.accountList;
        const sortList = [...accountList];
        sortList.sort((lhs, rhs) => {
          if (lhs.accountNm.toLowerCase() === rhs.accountNm.toLowerCase()) {
            return 0;
          }
          return lhs.accountNm.toLowerCase() > rhs.accountNm.toLowerCase() ? 1 : -1;
        });
        return sortList;
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ accountId }) => ({ type: 'PLACE-MEMBER-LIST', id: accountId })),
        { type: 'PLACE-MEMBER', id: 'LIST' },
      ],
    }),
    placeMemberLastLoginList: build.query({
      query: data => ({ url: '/core/place/member-list', data }),
      transformResponse: response => {
        const accountList = response.accountList;
        const sortList = [...accountList];
        sortList.sort((lhs, rhs) => {
          if (lhs.lastLoginDt.toLowerCase() === rhs.lastLoginDt.toLowerCase()) {
            return 0;
          }
          return lhs.lastLoginDt.toLowerCase() > rhs.lastLoginDt.toLowerCase() ? -1 : 1;
        });
        return sortList.slice(0, 4);
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ accountId }) => ({ type: 'PLACE-MEMBER-LIST', id: accountId })),
        { type: 'PLACE-MEMBER', id: 'LIST' },
      ],
    }),
    placeMemberAdd: build.mutation({
      query: data => ({ url: '/core/place/member-add', data }),
      invalidatesTags: [{ type: 'PLACE-MEMBER', id: 'LIST' }],
    }),
    placeMemberEdit: build.mutation({
      query: data => ({ url: '/core/place/member-edit', data }),
      invalidatesTags: [{ type: 'PLACE-MEMBER', id: 'LIST' }],
    }),
    placeMemberRemove: build.mutation({
      query: data => ({ url: '/core/place/member-remove', data }),
      invalidatesTags: [{ type: 'PLACE-MEMBER', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});
