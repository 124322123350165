import React, { Suspense } from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HashLoader from 'react-spinners/HashLoader';
import { ToastContainer } from 'react-toastify';

import { authSelector } from 'rtk/core/authSlice';
import { usePlaceDetailQuery } from 'rtk/core/placeApi';

import Token from 'pages/auth/Token';

const contentLoading = () => (
  <Loading>
    <HashLoader
      css={{
        display: 'block',
        width: '100%',
        height: '100%',
        margin: '0 auto',
      }}
      color={'#41a1ea'}
      loading={true}
      size={100}
    />
  </Loading>
);

const SpaceLayout = props => {
  const status = useSelector(authSelector.status);

  usePlaceDetailQuery(
    { placeId: sessionStorage.getItem('placeId') },
    { refetchOnMountOrArgChange: true, skip: !sessionStorage.getItem('placeId') },
  );

  return !status.isLogin ? (
    <Token />
  ) : (
    <Container>
      <Suspense fallback={contentLoading()}>
        <Outlet />
      </Suspense>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </Container>
  );
};

const Container = styled.div`
  min-width: 768px;
  height: 100%;
  display: flex;
`;

const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default SpaceLayout;
