import { createSlice, createDraftSafeSelector } from '@reduxjs/toolkit';

import { endpoints as playlistEndpoints } from './playlistApi';
import { endpoints as frameEndpoints } from './frameApi';
import { endpoints as layerEndpoints } from './layerApi';
import { endpoints as layerContentsEndpoints } from './layerContentsApi';
import { endpoints as overlayEndpoints } from './overlayApi';
import { endpoints as overlayContentsEndpoints } from './overlayContentsApi';

export const CursorType = {
  SELECT: 'SELECT',
  SCROLL: 'SCROLL',
  LAYER_ADD: 'LAYER_ADD',
  OVERLAY_ADD: 'OVERLAY_ADD',
};

export const TabType = {
  RIGHT_UP: 0,
  RIGHT_DOWN: 1,
};

export const TabIndex = {
  LAYER_LIST: 0,
  OVERLAY_LIST: 1,
  LAYER_PROPERTY: 0,
  APP_LIST: 1,
  DESIGN_LIST: 2,
};

const initialState = {
  cursorType: CursorType.SELECT,
  isScroll: false,
  zoom: 0,
  tabIndex: [TabIndex.LAYER_LIST, TabIndex.LAYER_PROPERTY],
  selectPlaylistId: '',
  selectFrameId: '',
  selectLayerId: '',
  selectOverlayId: '',
  selectLayerList: [],
  selectContentsType: '',
  playlistInfo: {},
  frameList: [],
  layerList: [],
  layerContentsList: [],
  overlayList: [],
  overlayContentsList: [],
};

const reducers = {
  setState: (state, { payload: { key, value } }) => {
    state[key] = value;
  },
  setStateList: (state, { payload }) => {
    for (const item of payload) {
      state[item.key] = item.value;
    }
  },
  setTabIndex: (state, { payload: { tabType, tabIndex } }) => {
    state.tabIndex[tabType] = tabIndex;
  },
  setLayerProperty: (state, { payload }) => {
    state.selectLayerList[0] = { ...state.selectLayerList[0], ...payload };
  },
};

const extraReducers = builder => {
  builder.addMatcher(playlistEndpoints.playlistDetail.matchPending, (state, { payload }) => {
    state.cursorType = CursorType.SELECT;
    state.isScroll = false;
    state.zoom = 0;
    state.tabIndex = [TabIndex.LAYER_LIST, TabIndex.LAYER_PROPERTY];
    state.selectPlaylistId = '';
    state.selectFrameId = '';
    state.selectLayerId = '';
    state.selectOverlayId = '';
    state.selectLayerList = [];
    state.selectContentsType = '';
    state.playlistInfo = {};
    state.frameList = [];
    state.layerList = [];
    state.layerContentsList = [];
    state.overlayList = [];
    state.overlayContentsList = [];
  });
  builder.addMatcher(playlistEndpoints.playlistDetail.matchFulfilled, (state, { payload }) => {
    state.selectPlaylistId = payload.playlistId;
    state.playlistInfo = payload;
  });
  builder.addMatcher(frameEndpoints.frameList.matchFulfilled, (state, { payload }) => {
    state.frameList = payload;
  });
  builder.addMatcher(layerEndpoints.layerList.matchFulfilled, (state, { payload }) => {
    state.layerList = payload;
  });
  builder.addMatcher(layerContentsEndpoints.layerContentsList.matchFulfilled, (state, { payload }) => {
    state.layerContentsList = payload;
  });
  builder.addMatcher(overlayEndpoints.overlayList.matchFulfilled, (state, { payload }) => {
    state.overlayList = payload;
  });
  builder.addMatcher(overlayContentsEndpoints.overlayContentsList.matchFulfilled, (state, { payload }) => {
    state.overlayContentsList = payload;
  });
};

const slice = createSlice({
  name: 'editor',
  initialState: initialState,
  reducers: reducers,
  extraReducers: extraReducers,
});

const cursorTypeSelector = createDraftSafeSelector(
  state => state.cursorType,
  cursorType => cursorType,
);

const isScrollSelector = createDraftSafeSelector(
  state => state.isScroll,
  isScroll => isScroll,
);

const zoomSelector = createDraftSafeSelector(
  state => state.zoom,
  zoom => zoom,
);

const tabIndexSelector = createDraftSafeSelector(
  state => state.tabIndex,
  tabIndex => tabIndex,
);

const selectPlaylistIdSelector = createDraftSafeSelector(
  state => state.selectPlaylistId,
  selectPlaylistId => selectPlaylistId,
);

const selectFrameIdSelector = createDraftSafeSelector(
  state => state.selectFrameId,
  selectFrameId => selectFrameId,
);

const selectLayerIdSelector = createDraftSafeSelector(
  state => state.selectLayerId,
  selectLayerId => selectLayerId,
);

const selectOverlayIdSelector = createDraftSafeSelector(
  state => state.selectOverlayId,
  selectOverlayId => selectOverlayId,
);

const selectLayerListSelector = createDraftSafeSelector(
  state => state.selectLayerList,
  selectLayerList => selectLayerList,
);

const selectContentsTypeSelector = createDraftSafeSelector(
  state => state.selectContentsType,
  selectContentsType => selectContentsType,
);

const playlistInfoSelector = createDraftSafeSelector(
  state => state.playlistInfo,
  playlistInfo => playlistInfo,
);

const frameListSelector = createDraftSafeSelector(
  state => state.frameList,
  frameList => frameList,
);

const layerListSelector = createDraftSafeSelector(
  state => state.layerList,
  layerList => layerList,
);

const layerContentsListSelector = createDraftSafeSelector(
  state => state.layerContentsList,
  layerContentsList => layerContentsList,
);

const overlayListSelector = createDraftSafeSelector(
  state => state.overlayList,
  overlayList => overlayList,
);

const overlayContentsListSelector = createDraftSafeSelector(
  state => state.overlayContentsList,
  overlayContentsList => overlayContentsList,
);

export const editorSelector = {
  cursorType: state => cursorTypeSelector(state[slice.name]),
  isScroll: state => isScrollSelector(state[slice.name]),
  zoom: state => zoomSelector(state[slice.name]),
  tabIndex: state => tabIndexSelector(state[slice.name]),
  selectPlaylistId: state => selectPlaylistIdSelector(state[slice.name]),
  selectFrameId: state => selectFrameIdSelector(state[slice.name]),
  selectLayerId: state => selectLayerIdSelector(state[slice.name]),
  selectOverlayId: state => selectOverlayIdSelector(state[slice.name]),
  selectLayerList: state => selectLayerListSelector(state[slice.name]),
  selectContentsType: state => selectContentsTypeSelector(state[slice.name]),
  playlistInfo: state => playlistInfoSelector(state[slice.name]),
  frameList: state => frameListSelector(state[slice.name]),
  layerList: state => layerListSelector(state[slice.name]),
  layerContentsList: state => layerContentsListSelector(state[slice.name]),
  overlayList: state => overlayListSelector(state[slice.name]),
  overlayContentsList: state => overlayContentsListSelector(state[slice.name]),
};

export const EDITOR = slice.name;
export const editorReducer = slice.reducer;
export const editorAction = slice.actions;
