import { createSlice, createDraftSafeSelector } from '@reduxjs/toolkit';
import { endpoints } from './groupApi';

const initialState = {
  groupId: '',
  groupList: [],
  groupInfo: {},
};

const reducers = {
  setState: (state, { payload: { key, value } }) => {
    state[key] = value;
  },
};

const extraReducers = builder => {
  builder.addMatcher(endpoints.groupList.matchFulfilled, (state, { payload }) => {
    state.groupList = payload;
  });
};

const slice = createSlice({
  name: 'group',
  initialState: initialState,
  reducers: reducers,
  extraReducers: extraReducers,
});

const selectGroupId = createDraftSafeSelector(
  state => state.groupId,
  groupId => groupId,
);

const selectGroupList = createDraftSafeSelector(
  state => state.groupList,
  groupList => groupList,
);

const selectGroupInfo = createDraftSafeSelector(
  state => state.groupInfo,
  groupInfo => groupInfo,
);

export const groupSelector = {
  groupId: state => selectGroupId(state[slice.name]),
  groupList: state => selectGroupList(state[slice.name]),
  groupInfo: state => selectGroupInfo(state[slice.name]),
};

export const GROUP = slice.name;
export const groupReducer = slice.reducer;
export const groupAction = slice.actions;
