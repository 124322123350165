import { createSlice, createDraftSafeSelector } from '@reduxjs/toolkit';
import { endpoints } from './deviceApi';

const initialState = {
  deviceId: '',
  deviceList: [],
  deviceInfo: {},
};

const reducers = {
  setState: (state, { payload: { key, value } }) => {
    state[key] = value;
  },
};

const extraReducers = builder => {
  builder.addMatcher(endpoints.deviceList.matchFulfilled, (state, { payload }) => {
    state.deviceList = payload;
  });
  builder.addMatcher(endpoints.deviceDetail.matchFulfilled, (state, { payload }) => {
    state.deviceInfo = payload;
  });
};

const slice = createSlice({
  name: 'device',
  initialState: initialState,
  reducers: reducers,
  extraReducers: extraReducers,
});

const selectDeviceId = createDraftSafeSelector(
  state => state.deviceId,
  deviceId => deviceId,
);

const selectDeviceList = createDraftSafeSelector(
  state => state.deviceList,
  deviceList => deviceList,
);

const selectDeviceInfo = createDraftSafeSelector(
  state => state.deviceInfo,
  deviceInfo => deviceInfo,
);

export const deviceSelector = {
  deviceId: state => selectDeviceId(state[slice.name]),
  deviceList: state => selectDeviceList(state[slice.name]),
  deviceInfo: state => selectDeviceInfo(state[slice.name]),
};

export const DEVICE = slice.name;
export const deviceReducer = slice.reducer;
export const deviceAction = slice.actions;
