import { managerBaseApi } from '../managerBaseApi';

export const {
  endpoints,
  useDeviceListQuery,
  useDeviceAllListQuery,
  useDeviceDetailQuery,
  useDeviceAddMutation,
  useDeviceEditMutation,
  useDeviceUpdateMutation,
  useDeviceRemoveMutation,
  useDevicePairingSetMutation,
  useDevicePairingUnsetMutation,
  useDevicePlayMutation,
  useDeviceStopMutation,
  useDeviceScrshotListQuery,
  useDeviceRemoteUrlMutation,
} = managerBaseApi.injectEndpoints({
  endpoints: build => ({
    deviceList: build.query({
      query: data => ({ url: '/core/device/list', data }),
      transformResponse: response => {
        const deviceList = response.deviceList;
        const sortList = [...deviceList];
        sortList.sort((lhs, rhs) => {
          if (lhs.deviceNm?.toLowerCase() === rhs.deviceNm?.toLowerCase()) {
            return 0;
          }
          return lhs.deviceNm?.toLowerCase() > rhs.deviceNm?.toLowerCase() ? 1 : -1;
        });
        return sortList;
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ deviceId }) => ({ type: 'DEVICE', id: deviceId })),
        { type: 'DEVICE', id: 'LIST' },
      ],
    }),
    deviceAllList: build.query({
      query: data => ({ url: '/core/device/list-by-place', data }),
      transformResponse: response => {
        const deviceList = response.deviceList;
        const sortList = [...deviceList];
        sortList.sort((lhs, rhs) => {
          if (lhs.deviceNm?.toLowerCase() === rhs.deviceNm?.toLowerCase()) {
            return 0;
          }
          return lhs.deviceNm?.toLowerCase() > rhs.deviceNm?.toLowerCase() ? 1 : -1;
        });
        return sortList;
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ deviceId }) => ({ type: 'DEVICE', id: deviceId })),
        { type: 'DEVICE', id: 'LIST' },
      ],
    }),
    deviceDetail: build.query({
      query: data => ({ url: '/core/device/detail', data }),
      transformResponse: response => {
        const deviceInfo = response.deviceInfo;
        return deviceInfo;
      },
      providesTags: (result, error, payload) => [{ type: 'DEVICE', id: payload.deviceId }],
    }),
    deviceAdd: build.mutation({
      query: data => ({ url: '/core/device/add', data }),
      invalidatesTags: [{ type: 'DEVICE', id: 'LIST' }],
    }),
    deviceEdit: build.mutation({
      query: data => ({ url: '/core/device/edit', data }),
      transformResponse: response => {
        const deviceInfo = response.deviceInfo;
        return deviceInfo;
      },
      invalidatesTags: (result, error, payload) => [{ type: 'DEVICE', id: payload.deviceId }],
    }),
    deviceUpdate: build.mutation({
      query: data => ({ url: '/core/device/update', data }),
      invalidatesTags: [{ type: 'DEVICE', id: 'LIST' }],
    }),
    deviceRemove: build.mutation({
      query: data => ({ url: '/core/device/remove', data }),
      invalidatesTags: [{ type: 'DEVICE', id: 'LIST' }],
    }),
    devicePairingSet: build.mutation({
      query: data => ({ url: '/core/device/pairing-set', data }),
      transformResponse: response => {
        const pairingInfo = response.pairingInfo;
        return pairingInfo;
      },
      invalidatesTags: (result, error, payload) => [{ type: 'DEVICE', id: payload.deviceId }],
    }),
    devicePairingUnset: build.mutation({
      query: data => ({ url: '/core/device/pairing-unset', data }),
      transformResponse: response => {
        const pairingInfo = response.pairingInfo;
        return pairingInfo;
      },
      invalidatesTags: (result, error, payload) => [{ type: 'DEVICE', id: payload.deviceId }],
    }),
    devicePlay: build.mutation({
      query: data => ({ url: '/core/device/play', data }),
      invalidatesTags: [{ type: 'DEVICE', id: 'LIST' }],
    }),
    deviceStop: build.mutation({
      query: data => ({ url: '/core/device/stop', data }),
      invalidatesTags: [{ type: 'DEVICE', id: 'LIST' }],
    }),
    deviceScrshotList: build.query({
      query: data => ({ url: '/core/device/scrshot-list', data }),
      transformResponse: response => {
        const totalCount = response.totalCount;
        const scrshotList = response.scrshotList;
        return { totalCount, scrshotList };
      },
    }),
    deviceRemoteUrl: build.mutation({
      query: data => ({ url: '/core/device/remote-url', data }),
      transformResponse: response => {
        const remoteUrl = response.remoteUrl;
        return remoteUrl;
      },
    }),
  }),
  overrideExisting: false,
});
