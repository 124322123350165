import { managerBaseApi } from '../managerBaseApi';

export const {
  endpoints,
  useSpaceListQuery,
  useSpaceLimitListQuery,
  useSpaceDetailQuery,
  useSpaceAddMutation,
  useSpaceEditMutation,
  useSpaceRemoveMutation,
  useSpaceMemberListQuery,
  useSpaceMemberAddMutation,
  useSpaceMemberEditMutation,
  useSpaceMemberRemoveMutation,
} = managerBaseApi.injectEndpoints({
  endpoints: build => ({
    spaceList: build.query({
      query: data => ({ url: '/core/space/list', data }),
      transformResponse: response => {
        const spaceList = response.spaceList;
        return spaceList;
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ spaceId }) => ({ type: 'SPACE', id: spaceId })),
        { type: 'SPACE', id: 'LIST' },
      ],
    }),
    spaceLimitList: build.query({
      query: data => ({ url: '/core/space/limit-list', data }),
      transformResponse: response => {
        const totalCount = response.totalCount;
        const spaceList = response.spaceList;
        return { totalCount, spaceList };
      },
      providesTags: (result, error, payload) => [
        ...result.spaceList.map(({ spaceId }) => ({ type: 'SPACE', id: spaceId })),
        { type: 'SPACE', id: 'LIST' },
      ],
      keepUnusedDataFor: 0,
    }),
    spaceDetail: build.query({
      query: data => ({ url: '/core/space/detail', data }),
      transformResponse: response => {
        const spaceInfo = response.spaceInfo;
        return spaceInfo;
      },
      providesTags: (result, error, payload) => [{ type: 'SPACE', id: payload.spaceId }],
    }),
    spaceAdd: build.mutation({
      query: data => ({ url: '/core/space/add', data }),
      invalidatesTags: [{ type: 'SPACE', id: 'LIST' }],
    }),
    spaceEdit: build.mutation({
      query: data => ({ url: '/core/space/edit', data }),
      invalidatesTags: (result, error, payload) => [{ type: 'SPACE', id: payload.spaceId }],
    }),
    spaceRemove: build.mutation({
      query: data => ({ url: '/core/space/remove', data }),
      invalidatesTags: [{ type: 'SPACE', id: 'LIST' }],
    }),
    spaceMemberList: build.query({
      query: data => ({ url: '/core/space/member-list', data }),
      transformResponse: response => {
        const memberList = response.memberList;
        if (!memberList) {
          return [];
        }
        const sortList = [...memberList];
        sortList.sort((lhs, rhs) => {
          if (lhs.accountNm.toLowerCase() === rhs.accountNm.toLowerCase()) {
            return 0;
          }
          return lhs.accountNm.toLowerCase() > rhs.accountNm.toLowerCase() ? 1 : -1;
        });
        return sortList;
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ accountId }) => ({ type: 'SPACE-MEMBER', id: accountId })),
        { type: 'SPACE-MEMBER', id: 'LIST' },
      ],
    }),
    spaceMemberAdd: build.mutation({
      query: data => ({ url: '/core/space/member-add', data }),
      invalidatesTags: [{ type: 'SPACE-MEMBER', id: 'LIST' }],
    }),
    spaceMemberEdit: build.mutation({
      query: data => ({ url: '/core/space/member-edit', data }),
      invalidatesTags: [{ type: 'SPACE-MEMBER', id: 'LIST' }],
    }),
    spaceMemberRemove: build.mutation({
      query: data => ({ url: '/core/space/member-remove', data }),
      invalidatesTags: [{ type: 'SPACE-MEMBER', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});
