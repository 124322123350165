import React, { Suspense } from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

const Error = () => {
  return (
    <Container>
      <ErrorMsg>
        <Suspense>
          <Outlet />
        </Suspense>
      </ErrorMsg>
      <LogoutBtn onClick={e => (window.location = '/auth/login')}>Login</LogoutBtn>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ErrorMsg = styled.div`
  font-weight: 700;
  font-size: 20px;
`;

const LogoutBtn = styled.div`
  padding: 4px 10px;
  border: 1px solid #333333;
  border-radius: 6px;
  cursor: pointer;
`;

export default React.memo(Error);
