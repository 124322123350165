import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { CONFIG, configReducer } from './core/configSlice';
import { AUTH, authReducer } from './core/authSlice';
import { SPACE, spaceReducer } from './core/spaceSlice';
import { PLACE, placeReducer } from './core/placeSlice';
import { GROUP, groupReducer } from './core/groupSlice';
import { DEVICE, deviceReducer } from './core/deviceSlice';
import { EDITOR, editorReducer } from './interaction/editorSlice';
import { managerBaseApi } from './managerBaseApi';

const createRootReducer = () =>
  combineReducers({
    [CONFIG]: configReducer,
    [AUTH]: authReducer,
    [SPACE]: spaceReducer,
    [PLACE]: placeReducer,
    [GROUP]: groupReducer,
    [DEVICE]: deviceReducer,
    [EDITOR]: editorReducer,
    [managerBaseApi.reducerPath]: managerBaseApi.reducer,
  });

export default function createStore() {
  const store = configureStore({
    reducer: createRootReducer(),
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({ serializableCheck: false }),
      managerBaseApi.middleware,
    ],
  });

  return store;
}
