import { managerBaseApi } from '../managerBaseApi';

const overlayContentsApi = managerBaseApi.injectEndpoints({
  endpoints: build => ({
    overlayContentsList: build.query({
      query: data => ({ url: '/interaction/overlay-contents/list', data }),
      transformResponse: response => {
        if (!response.resultFlag) {
          return [];
        }

        const overlayContentsList = response.overlayContentsList;
        return overlayContentsList;
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ contentsId }) => ({ type: 'OVERLAY-CONTENTS', id: contentsId })),
        { type: 'OVERLAY-CONTENTS', id: 'LIST' },
      ],
    }),
    overlayContentsAdd: build.mutation({
      query: data => ({ url: '/interaction/overlay-contents/add', data }),
      invalidatesTags: [{ type: 'OVERLAY-CONTENTS', id: 'LIST' }],
      async onQueryStarted(payload, { dispatch, queryFulfilled, getState }) {
        let patchResult = undefined;
        try {
          const { data } = await queryFulfilled;
          if (data.resultFlag) {
            const contentsInfo = data.contentsInfo;
            if (contentsInfo) {
              patchResult = dispatch(
                overlayContentsApi.util.updateQueryData(
                  'overlayContentsList',
                  { overlayId: contentsInfo.overlayId },
                  draft => {
                    const index = draft.findIndex(contents => contents.contentsId === contentsInfo.contentsId);
                    draft[index] = { ...draft[index], ...contentsInfo };
                  },
                ),
              );
            }
          }
        } catch {
          patchResult && patchResult.undo();
        }
      },
    }),
    overlayContentsUpdate: build.mutation({
      query: data => ({ url: '/interaction/overlay-contents/update', data }),
      invalidatesTags: [{ type: 'OVERLAY-CONTENTS', id: 'LIST' }],
      async onQueryStarted({ contentsId, contentsData, contentsFileList }, { dispatch, queryFulfilled, getState }) {
        let patchResult = undefined;
        try {
          dispatch(
            overlayContentsApi.util.updateQueryData('overlayContentsList', { contentsId }, draft => {
              const index = draft.findIndex(contents => contents.contentsId === contentsId);
              draft[index] = { ...draft[index], contentsData, contentsFileList };
            }),
          );
        } catch (error) {
          patchResult && patchResult.undo();
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  endpoints,
  useOverlayContentsListQuery,
  useOverlayContentsAddMutation,
  useOverlayContentsUpdateMutation,
} = overlayContentsApi;
