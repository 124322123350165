import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from 'rtk/core/authSlice';
import { spaceSelector } from 'rtk/core/spaceSlice';
import { placeSelector } from 'rtk/core/placeSlice';

export const ROLES = {
  SYSTEM_ADMIN: 101,
  SYSTEM_OWNER: 102,
  SYSTEM_EDITOR: 103,
  SYSTEM_VIEWER: 104,
  SPACE_OWNER: 201,
  SPACE_EDITOR: 202,
  SPACE_VIEWER: 203,
  PLACE_OWNER: 301,
  PLACE_EDITOR: 302,
  PLACE_VIEWER: 303,
};

export const PERMISSIONS = {
  USE_PAGE_ADMIN: 1000,
  USE_PAGE_ADMIN_SPACE: 1001,
  USE_PAGE_ADMIN_ACCOUNT: 1006,
  USE_PAGE_ADMIN_AGENT: 1007,
  USE_PAGE_ADMIN_MANUAL: 1008,
  USE_PAGE_SPACE: 1100,
  CAN_LIST_SPACE_PLACE: 1101,
  CAN_ADD_SPACE_PLACE: 1102,
  CAN_UPDATE_SPACE_PLACE: 1103,
  CAN_REMOVE_SPACE_PLACE: 1104,
  CAN_LIST_SPACE_MEMBER: 1105,
  CAN_ADD_SPACE_MEMBER: 1106,
  CAN_UPDATE_SPACE_MEMBER: 1107,
  CAN_REMOVE_SPACE_MEMBER: 1108,
  USE_PAGE_PLACE_INDEX: 1201,
  USE_PAGE_PLACE_DEVICE: 1210,
  CAN_LIST_PLACE_DEVICE: 1211,
  CAN_ADD_PLACE_DEVICE: 1212,
  CAN_UPDATE_PLACE_DEVICE: 1213,
  CAN_REMOVE_PLACE_DEVICE: 1214,
  USE_PAGE_PLACE_STORAGE: 1220,
  CAN_LIST_PLACE_STORAGE: 1221,
  CAN_ADD_PLACE_STORAGE: 1222,
  CAN_UPDATE_PLACE_STORAGE: 1223,
  CAN_REMOVE_PLACE_STORAGE: 1224,
  USE_PAGE_PLACE_PLAYLIST: 1230,
  CAN_LIST_PLACE_PLAYLIST: 1231,
  CAN_ADD_PLACE_PLAYLIST: 1232,
  CAN_UPDATE_PLACE_PLAYLIST: 1233,
  CAN_REMOVE_PLACE_PLAYLIST: 1234,
  USE_PAGE_PLACE_EDITOR: 1240,
  CAN_LIST_PLACE_FRAME: 1241,
  CAN_ADD_PLACE_FRAME: 1242,
  CAN_UPDATE_PLACE_FRAME: 1243,
  CAN_REMOVE_PLACE_FRAME: 1244,
  CAN_LIST_PLACE_LAYER: 1245,
  CAN_ADD_PLACE_LAYER: 1246,
  CAN_UPDATE_PLACE_LAYER: 1247,
  CAN_REMOVE_PLACE_LAYER: 1248,
  USE_PAGE_PLACE_DCAVE: 1300,
  USE_PAGE_PLACE_DCAVE_COLLECTION: 1301,
  CAN_LIST_PLACE_DCAVE_COLLECTION: 1302,
  CAN_ADD_PLACE_DCAVE_COLLECTION: 1303,
  CAN_UPDATE_PLACE_DCAVE_COLLECTION: 1304,
  CAN_REMOVE_PLACE_DCAVE_COLLECTION: 1305,
  USE_PAGE_PLACE_DCAVE_CATEGORY: 1310,
  CAN_LIST_PLACE_DCAVE_CATEGORY: 1311,
  CAN_ADD_PLACE_DCAVE_CATEGORY: 1312,
  CAN_UPDATE_PLACE_DCAVE_CATEGORY: 1313,
  CAN_REMOVE_PLACE_DCAVE_CATEGORY: 1314,
  USE_PAGE_PLACE_DCAVE_CURATION: 1320,
  CAN_LIST_PLACE_DCAVE_CURATION: 1321,
  CAN_ADD_PLACE_DCAVE_CURATION: 1322,
  CAN_UPDATE_PLACE_DCAVE_CURATION: 1323,
  CAN_REMOVE_PLACE_DCAVE_CURATION: 1324,
  USE_PAGE_PLACE_DCAVE_EXHIBITION: 1330,
  CAN_LIST_PLACE_DCAVE_EXHIBITION: 1331,
  CAN_ADD_PLACE_DCAVE_EXHIBITION: 1332,
  CAN_UPDATE_PLACE_DCAVE_EXHIBITION: 1333,
  CAN_REMOVE_PLACE_DCAVE_EXHIBITION: 1334,
  USE_PAGE_PLACE_JAMPIC: 1400,
  USE_PAGE_PLACE_REPORT: 1800,
  USE_PAGE_PLACE_REPORT_COLLECTION: 1801,
  USE_PAGE_PLACE_REPORT_EXHIBITON: 1802,
  USE_PAGE_PLACE_REPORT_CONTENTS: 1803,
  USE_PAGE_PLACE_SYSTEM: 1900,
  USE_PAGE_PLACE_SYSTEM_PLACE: 1910,
  CAN_UPDATE_PLACE_SYSTEM_PLACE: 1911,
  USE_PAGE_PLACE_SYSTEM_PACK: 1920,
  CAN_LIST_PLACE_SYSTEM_PACK: 1921,
  CAN_ADD_PLACE_SYSTEM_PACK: 1922,
  CAN_UPDATE_PLACE_SYSTEM_PACK: 1923,
  CAN_REMOVE_PLACE_SYSTEM_PACK: 1924,
  USE_PAGE_PLACE_SYSTEM_MEMBER: 1930,
  CAN_LIST_PLACE_SYSTEM_MEMBER: 1931,
  CAN_ADD_PLACE_SYSTEM_MEMBER: 1932,
  CAN_UPDATE_PLACE_SYSTEM_MEMBER: 1933,
  CAN_REMOVE_PLACE_SYSTEM_MEMBER: 1934,
  USE_PAGE_PLACE_SYSTEM_USER: 1940,
  CAN_LIST_PLACE_SYSTEM_USER: 1941,
  CAN_ADD_PLACE_SYSTEM_USER: 1942,
  CAN_UPDATE_PLACE_SYSTEM_USER: 1943,
  CAN_REMOVE_PLACE_SYSTEM_USER: 1944,
};

export const ROLES_PERMISIONS = {
  [ROLES.SYSTEM_ADMIN]: [
    PERMISSIONS.USE_PAGE_ADMIN,
    PERMISSIONS.USE_PAGE_ADMIN_SPACE,
    PERMISSIONS.USE_PAGE_ADMIN_ACCOUNT,
    PERMISSIONS.USE_PAGE_ADMIN_AGENT,
    PERMISSIONS.USE_PAGE_ADMIN_MANUAL,
  ],
  [ROLES.SYSTEM_OWNER]: [
    PERMISSIONS.USE_PAGE_ADMIN,
    PERMISSIONS.USE_PAGE_ADMIN_SPACE,
    PERMISSIONS.USE_PAGE_ADMIN_ACCOUNT,
  ],
  [ROLES.SYSTEM_EDITOR]: [PERMISSIONS.USE_PAGE_ADMIN, PERMISSIONS.USE_PAGE_ADMIN_SPACE],
  [ROLES.SYSTEM_VIEWER]: [],
  [ROLES.SPACE_OWNER]: [
    PERMISSIONS.USE_PAGE_SPACE,
    PERMISSIONS.CAN_LIST_SPACE_PLACE,
    PERMISSIONS.CAN_ADD_SPACE_PLACE,
    PERMISSIONS.CAN_UPDATE_SPACE_PLACE,
    PERMISSIONS.CAN_REMOVE_SPACE_PLACE,
    PERMISSIONS.CAN_LIST_SPACE_MEMBER,
    PERMISSIONS.CAN_ADD_SPACE_MEMBER,
    PERMISSIONS.CAN_UPDATE_SPACE_MEMBER,
    PERMISSIONS.CAN_REMOVE_SPACE_MEMBER,
  ],
  [ROLES.SPACE_EDITOR]: [
    PERMISSIONS.USE_PAGE_SPACE,
    PERMISSIONS.CAN_LIST_SPACE_PLACE,
    PERMISSIONS.CAN_LIST_SPACE_MEMBER,
  ],
  [ROLES.SPACE_VIEWER]: [
    PERMISSIONS.USE_PAGE_SPACE,
    PERMISSIONS.CAN_LIST_SPACE_PLACE,
    PERMISSIONS.CAN_LIST_SPACE_MEMBER,
  ],
  [ROLES.PLACE_OWNER]: [
    PERMISSIONS.USE_PAGE_PLACE_INDEX,
    PERMISSIONS.USE_PAGE_PLACE_DEVICE,
    PERMISSIONS.CAN_LIST_PLACE_DEVICE,
    PERMISSIONS.CAN_ADD_PLACE_DEVICE,
    PERMISSIONS.CAN_UPDATE_PLACE_DEVICE,
    PERMISSIONS.CAN_REMOVE_PLACE_DEVICE,
    PERMISSIONS.USE_PAGE_PLACE_STORAGE,
    PERMISSIONS.CAN_LIST_PLACE_STORAGE,
    PERMISSIONS.CAN_ADD_PLACE_STORAGE,
    PERMISSIONS.CAN_UPDATE_PLACE_STORAGE,
    PERMISSIONS.CAN_REMOVE_PLACE_STORAGE,
    PERMISSIONS.USE_PAGE_PLACE_PLAYLIST,
    PERMISSIONS.CAN_LIST_PLACE_PLAYLIST,
    PERMISSIONS.CAN_ADD_PLACE_PLAYLIST,
    PERMISSIONS.CAN_UPDATE_PLACE_PLAYLIST,
    PERMISSIONS.CAN_REMOVE_PLACE_PLAYLIST,
    PERMISSIONS.USE_PAGE_PLACE_EDITOR,
    PERMISSIONS.CAN_LIST_PLACE_FRAME,
    PERMISSIONS.CAN_ADD_PLACE_FRAME,
    PERMISSIONS.CAN_UPDATE_PLACE_FRAME,
    PERMISSIONS.CAN_REMOVE_PLACE_FRAME,
    PERMISSIONS.CAN_LIST_PLACE_LAYER,
    PERMISSIONS.CAN_ADD_PLACE_LAYER,
    PERMISSIONS.CAN_UPDATE_PLACE_LAYER,
    PERMISSIONS.CAN_REMOVE_PLACE_LAYER,
    PERMISSIONS.USE_PAGE_PLACE_DCAVE,
    PERMISSIONS.USE_PAGE_PLACE_DCAVE_COLLECTION,
    PERMISSIONS.CAN_LIST_PLACE_DCAVE_COLLECTION,
    PERMISSIONS.CAN_ADD_PLACE_DCAVE_COLLECTION,
    PERMISSIONS.CAN_UPDATE_PLACE_DCAVE_COLLECTION,
    PERMISSIONS.CAN_REMOVE_PLACE_DCAVE_COLLECTION,
    PERMISSIONS.USE_PAGE_PLACE_DCAVE_CATEGORY,
    PERMISSIONS.CAN_LIST_PLACE_DCAVE_CATEGORY,
    PERMISSIONS.CAN_ADD_PLACE_DCAVE_CATEGORY,
    PERMISSIONS.CAN_UPDATE_PLACE_DCAVE_CATEGORY,
    PERMISSIONS.CAN_REMOVE_PLACE_DCAVE_CATEGORY,
    PERMISSIONS.USE_PAGE_PLACE_DCAVE_CURATION,
    PERMISSIONS.CAN_LIST_PLACE_DCAVE_CURATION,
    PERMISSIONS.CAN_ADD_PLACE_DCAVE_CURATION,
    PERMISSIONS.CAN_UPDATE_PLACE_DCAVE_CURATION,
    PERMISSIONS.CAN_REMOVE_PLACE_DCAVE_CURATION,
    PERMISSIONS.USE_PAGE_PLACE_DCAVE_EXHIBITION,
    PERMISSIONS.CAN_LIST_PLACE_DCAVE_EXHIBITION,
    PERMISSIONS.CAN_ADD_PLACE_DCAVE_EXHIBITION,
    PERMISSIONS.CAN_UPDATE_PLACE_DCAVE_EXHIBITION,
    PERMISSIONS.CAN_REMOVE_PLACE_DCAVE_EXHIBITION,
    PERMISSIONS.USE_PAGE_PLACE_JAMPIC,
    PERMISSIONS.USE_PAGE_PLACE_REPORT,
    PERMISSIONS.USE_PAGE_PLACE_REPORT_COLLECTION,
    PERMISSIONS.USE_PAGE_PLACE_REPORT_EXHIBITON,
    PERMISSIONS.USE_PAGE_PLACE_REPORT_CONTENTS,
    PERMISSIONS.USE_PAGE_PLACE_SYSTEM,
    PERMISSIONS.USE_PAGE_PLACE_SYSTEM_PLACE,
    PERMISSIONS.CAN_UPDATE_PLACE_SYSTEM_PLACE,
    PERMISSIONS.USE_PAGE_PLACE_SYSTEM_PACK,
    PERMISSIONS.CAN_LIST_PLACE_SYSTEM_PACK,
    PERMISSIONS.CAN_ADD_PLACE_SYSTEM_PACK,
    PERMISSIONS.CAN_UPDATE_PLACE_SYSTEM_PACK,
    PERMISSIONS.CAN_REMOVE_PLACE_SYSTEM_PACK,
    PERMISSIONS.USE_PAGE_PLACE_SYSTEM_MEMBER,
    PERMISSIONS.CAN_LIST_PLACE_SYSTEM_MEMBER,
    PERMISSIONS.CAN_ADD_PLACE_SYSTEM_MEMBER,
    PERMISSIONS.CAN_UPDATE_PLACE_SYSTEM_MEMBER,
    PERMISSIONS.CAN_REMOVE_PLACE_SYSTEM_MEMBER,
    PERMISSIONS.USE_PAGE_PLACE_SYSTEM_USER,
    PERMISSIONS.CAN_LIST_PLACE_SYSTEM_USER,
    PERMISSIONS.CAN_ADD_PLACE_SYSTEM_USER,
    PERMISSIONS.CAN_UPDATE_PLACE_SYSTEM_USER,
    PERMISSIONS.CAN_REMOVE_PLACE_SYSTEM_USER,
  ],
  [ROLES.PLACE_EDITOR]: [
    PERMISSIONS.USE_PAGE_PLACE_INDEX,
    PERMISSIONS.USE_PAGE_PLACE_DEVICE,
    PERMISSIONS.CAN_LIST_PLACE_DEVICE,
    PERMISSIONS.CAN_ADD_PLACE_DEVICE,
    PERMISSIONS.CAN_UPDATE_PLACE_DEVICE,
    PERMISSIONS.CAN_REMOVE_PLACE_DEVICE,
    PERMISSIONS.USE_PAGE_PLACE_STORAGE,
    PERMISSIONS.CAN_LIST_PLACE_STORAGE,
    PERMISSIONS.CAN_ADD_PLACE_STORAGE,
    PERMISSIONS.CAN_UPDATE_PLACE_STORAGE,
    PERMISSIONS.CAN_REMOVE_PLACE_STORAGE,
    PERMISSIONS.USE_PAGE_PLACE_PLAYLIST,
    PERMISSIONS.CAN_LIST_PLACE_PLAYLIST,
    PERMISSIONS.CAN_ADD_PLACE_PLAYLIST,
    PERMISSIONS.CAN_UPDATE_PLACE_PLAYLIST,
    PERMISSIONS.CAN_REMOVE_PLACE_PLAYLIST,
    PERMISSIONS.USE_PAGE_PLACE_EDITOR,
    PERMISSIONS.CAN_LIST_PLACE_FRAME,
    PERMISSIONS.CAN_ADD_PLACE_FRAME,
    PERMISSIONS.CAN_UPDATE_PLACE_FRAME,
    PERMISSIONS.CAN_REMOVE_PLACE_FRAME,
    PERMISSIONS.CAN_LIST_PLACE_LAYER,
    PERMISSIONS.CAN_ADD_PLACE_LAYER,
    PERMISSIONS.CAN_UPDATE_PLACE_LAYER,
    PERMISSIONS.CAN_REMOVE_PLACE_LAYER,
    PERMISSIONS.USE_PAGE_PLACE_DCAVE,
    PERMISSIONS.USE_PAGE_PLACE_DCAVE_COLLECTION,
    PERMISSIONS.CAN_LIST_PLACE_DCAVE_COLLECTION,
    PERMISSIONS.CAN_ADD_PLACE_DCAVE_COLLECTION,
    PERMISSIONS.CAN_UPDATE_PLACE_DCAVE_COLLECTION,
    PERMISSIONS.CAN_REMOVE_PLACE_DCAVE_COLLECTION,
    PERMISSIONS.USE_PAGE_PLACE_DCAVE_CATEGORY,
    PERMISSIONS.CAN_LIST_PLACE_DCAVE_CATEGORY,
    PERMISSIONS.CAN_ADD_PLACE_DCAVE_CATEGORY,
    PERMISSIONS.CAN_UPDATE_PLACE_DCAVE_CATEGORY,
    PERMISSIONS.CAN_REMOVE_PLACE_DCAVE_CATEGORY,
    PERMISSIONS.USE_PAGE_PLACE_DCAVE_CURATION,
    PERMISSIONS.CAN_LIST_PLACE_DCAVE_CURATION,
    PERMISSIONS.CAN_ADD_PLACE_DCAVE_CURATION,
    PERMISSIONS.CAN_UPDATE_PLACE_DCAVE_CURATION,
    PERMISSIONS.CAN_REMOVE_PLACE_DCAVE_CURATION,
    PERMISSIONS.USE_PAGE_PLACE_DCAVE_EXHIBITION,
    PERMISSIONS.CAN_LIST_PLACE_DCAVE_EXHIBITION,
    PERMISSIONS.CAN_ADD_PLACE_DCAVE_EXHIBITION,
    PERMISSIONS.CAN_UPDATE_PLACE_DCAVE_EXHIBITION,
    PERMISSIONS.CAN_REMOVE_PLACE_DCAVE_EXHIBITION,
    PERMISSIONS.USE_PAGE_PLACE_JAMPIC,
    PERMISSIONS.USE_PAGE_PLACE_REPORT,
    PERMISSIONS.USE_PAGE_PLACE_REPORT_COLLECTION,
    PERMISSIONS.USE_PAGE_PLACE_REPORT_EXHIBITON,
    PERMISSIONS.USE_PAGE_PLACE_REPORT_CONTENTS,
    PERMISSIONS.USE_PAGE_PLACE_SYSTEM,
    PERMISSIONS.USE_PAGE_PLACE_SYSTEM_PLACE,
    PERMISSIONS.CAN_UPDATE_PLACE_SYSTEM_PLACE,
    PERMISSIONS.USE_PAGE_PLACE_SYSTEM_MEMBER,
    PERMISSIONS.CAN_LIST_PLACE_SYSTEM_MEMBER,
    PERMISSIONS.USE_PAGE_PLACE_SYSTEM_PACK,
    PERMISSIONS.USE_PAGE_PLACE_SYSTEM_USER,
  ],
  [ROLES.PLACE_VIEWER]: [
    PERMISSIONS.USE_PAGE_PLACE_INDEX,
    PERMISSIONS.USE_PAGE_PLACE_DEVICE,
    PERMISSIONS.CAN_LIST_PLACE_DEVICE,
  ],
};

const getPermissions = role_key => {
  return ROLES_PERMISIONS[role_key];
};

export const useHasRoles = () => {
  const loginInfo = useSelector(authSelector.loginInfo);
  const selectedSpacePermissionType = useSelector(spaceSelector.permissionType);
  const placeInfo = useSelector(placeSelector.placeInfo);

  const hasSystemRoles = useCallback(
    roleNames => {
      if (loginInfo.loginType === 'account' && loginInfo.accountType === 'ADMIN') {
        return ROLES.SYSTEM_ADMIN === roleNames;
      } else if (loginInfo.loginType === 'account' && loginInfo.accountType === 'OWNER') {
        return ROLES.SYSTEM_OWNER === roleNames;
      } else if (loginInfo.loginType === 'account' && loginInfo.accountType === 'EDITOR') {
        return ROLES.SYSTEM_EDITOR === roleNames;
      } else if (loginInfo.loginType === 'account' && loginInfo.accountType === 'VIEWER') {
        return ROLES.SYSTEM_VIEWER === roleNames;
      }

      return false;
    },
    [loginInfo],
  );

  const hasSpaceRoles = useCallback(
    roleNames => {
      if (loginInfo.loginType === 'account' && selectedSpacePermissionType === 'OWNER') {
        return ROLES.SPACE_OWNER === roleNames;
      } else if (loginInfo.loginType === 'account' && selectedSpacePermissionType === 'EDITOR') {
        return ROLES.SPACE_EDITOR === roleNames;
      } else if (loginInfo.loginType === 'account') {
        return ROLES.SPACE_VIEWER === roleNames;
      }

      return false;
    },
    [loginInfo, selectedSpacePermissionType],
  );

  const hasPlaceRoles = useCallback(
    roleNames => {
      if (placeInfo.permissionType === 'OWNER') {
        return ROLES.PLACE_OWNER === roleNames;
      } else if (placeInfo.permissionType === 'EDITOR') {
        return ROLES.PLACE_EDITOR === roleNames;
      } else if (placeInfo.permissionType === 'VIEWER') {
        return ROLES.PLACE_VIEWER === roleNames;
      }

      return false;
    },
    [placeInfo],
  );

  return {
    hasSystemRoles,
    hasSpaceRoles,
    hasPlaceRoles,
  };
};

export const useHasPermissions = () => {
  const loginInfo = useSelector(authSelector.loginInfo);
  const selectedSpacePermissionType = useSelector(spaceSelector.permissionType);
  const placeInfo = useSelector(placeSelector.placeInfo);

  const hasSystemPermissions = useCallback(
    permissionNames => {
      let permissions = null;

      if (loginInfo.loginType === 'account' && loginInfo.accountType === 'ADMIN') {
        permissions = getPermissions(ROLES.SYSTEM_ADMIN);
      } else if (loginInfo.loginType === 'account' && loginInfo.accountType === 'OWNER') {
        permissions = getPermissions(ROLES.SYSTEM_OWNER);
      } else if (loginInfo.loginType === 'account' && loginInfo.accountType === 'EDITOR') {
        permissions = getPermissions(ROLES.SYSTEM_EDITOR);
      } else if (loginInfo.loginType === 'account' && loginInfo.accountType === 'VIEWER') {
        permissions = getPermissions(ROLES.SYSTEM_VIEWER);
      }

      if (!permissions) {
        return false;
      }

      if (typeof permissionNames === 'number') {
        return permissions.includes?.(permissionNames);
      } else if (Array.isArray(permissionNames)) {
        return permissions.some(permissionName => Boolean(permissionNames.includes?.(permissionName)));
      } else {
        return false;
      }
    },
    [loginInfo],
  );

  const hasSpacePermissions = useCallback(
    permissionNames => {
      let permissions = null;

      if (loginInfo.loginType === 'account' && selectedSpacePermissionType === 'OWNER') {
        permissions = getPermissions(ROLES.SPACE_OWNER);
      } else if (loginInfo.loginType === 'account' && selectedSpacePermissionType === 'EDITOR') {
        permissions = getPermissions(ROLES.SPACE_EDITOR);
      } else if (loginInfo.loginType === 'account') {
        permissions = getPermissions(ROLES.SPACE_VIEWER);
      }

      if (!permissions) {
        return false;
      }

      if (typeof permissionNames === 'number') {
        return permissions.includes?.(permissionNames);
      } else if (Array.isArray(permissionNames)) {
        return permissions.some(permissionName => Boolean(permissionNames.includes?.(permissionName)));
      } else {
        return false;
      }
    },
    [loginInfo, selectedSpacePermissionType],
  );

  const hasPlacePermissions = useCallback(
    permissionNames => {
      let permissions = null;

      if (placeInfo.permissionType === 'OWNER') {
        permissions = getPermissions(ROLES.PLACE_OWNER);
      } else if (placeInfo.permissionType === 'EDITOR') {
        permissions = getPermissions(ROLES.PLACE_EDITOR);
      } else if (placeInfo.permissionType === 'VIEWER') {
        permissions = getPermissions(ROLES.PLACE_VIEWER);
      }

      if (!permissions) {
        return false;
      }

      if (typeof permissionNames === 'number') {
        return permissions.includes?.(permissionNames);
      } else if (Array.isArray(permissionNames)) {
        return permissions.some(permissionName => Boolean(permissionNames.includes?.(permissionName)));
      } else {
        return false;
      }
    },
    [placeInfo],
  );

  return {
    hasSystemPermissions,
    hasSpacePermissions,
    hasPlacePermissions,
  };
};
