import React from 'react';
import { BrowserRouter, Navigate, useRoutes } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

import { useHasPermissions, PERMISSIONS } from 'hooks/useHasPermissions';

import ErrorBoundary from './ErrorBoundary';
import AuthLayout from 'pages/layouts/Auth';
import SpaceLayout from 'pages/layouts/Space';
import PlaceLayout from 'pages/layouts/Place';
import EditorLayout from 'pages/layouts/Editor';
import ErrorLayout from 'pages/layouts/Error';

const Login = React.lazy(() => import('pages/auth/Login'));
const Logout = React.lazy(() => import('pages/auth/Logout'));
const Root = React.lazy(() => import('pages/auth/Root'));

const SpaceIndex = React.lazy(() => import('pages/space/SpaceIndex'));
const AdminIndex = React.lazy(() => import('pages/admin/AdminIndex'));
const SpaceList = React.lazy(() => import('pages/admin/space/SpaceList'));
const SpaceAdd = React.lazy(() => import('pages/admin/space/SpaceAdd'));
const SpaceDetail = React.lazy(() => import('pages/admin/space/SpaceDetail'));
const AccountList = React.lazy(() => import('pages/admin/account/AccountList'));
const AccountAdd = React.lazy(() => import('pages/admin/account/AccountAdd'));
const AccountDetail = React.lazy(() => import('pages/admin/account/AccountDetail'));
const TemplateIndex = React.lazy(() => import('pages/admin/template/TemplateIndex'));
const PlaceIndex = React.lazy(() => import('pages/place/PlaceIndex'));
const DeviceIndex = React.lazy(() => import('pages/device/DeviceIndex'));
const DeviceDetail = React.lazy(() => import('pages/device/DeviceDetail'));
const StorageIndex = React.lazy(() => import('pages/storage/StorageIndex'));
const PlaylistIndex = React.lazy(() => import('pages/playlist/PlaylistIndex'));
const EditorIndex = React.lazy(() => import('pages/editor/EditorIndex'));
const SettingIndex = React.lazy(() => import('pages/system/SettingIndex'));
const PlaceDetail = React.lazy(() => import('pages/system/PlaceDetail'));
const PackList = React.lazy(() => import('pages/system/PackList'));
const PackAdd = React.lazy(() => import('pages/system/PackAdd'));
const PackDetail = React.lazy(() => import('pages/system/PackDetail'));
const UserList = React.lazy(() => import('pages/system/UserList'));
const UserAdd = React.lazy(() => import('pages/system/UserAdd'));
const UserDetail = React.lazy(() => import('pages/system/UserDetail'));
const UserProfile = React.lazy(() => import('pages/system/Profile'));
const MemberList = React.lazy(() => import('pages/system/MemberList'));

const Routes = () => {
  const { hasSystemPermissions, hasSpacePermissions, hasPlacePermissions } = useHasPermissions();

  const noMatchRoutes: RouteObject = {
    path: '*',
    element: <Navigate to="/" />,
  };

  const authRoutes: RouteObject = {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <Root />,
      },
      {
        path: 'auth/login',
        element: <Login />,
      },
      {
        path: 'auth/logout',
        element: <Logout />,
      },
    ],
  };

  const spaceRoutes: RouteObject = {
    path: '/space',
    element: <SpaceLayout />,
    children: [
      {
        index: true,
        element: <SpaceIndex />,
      },
    ],
  };

  const adminRoutes: RouteObject = {
    path: '/admin',
    element: <SpaceLayout />,
    children: [
      {
        element: <AdminIndex />,
        children: [
          {
            path: 'space/list',
            element: hasSystemPermissions(PERMISSIONS.USE_PAGE_ADMIN_SPACE) && <SpaceList />,
          },
          {
            path: 'space/add',
            element: hasSystemPermissions(PERMISSIONS.USE_PAGE_ADMIN_SPACE) && <SpaceAdd />,
          },
          {
            path: 'space/detail',
            element: hasSystemPermissions(PERMISSIONS.USE_PAGE_ADMIN_SPACE) && <SpaceDetail />,
          },
          {
            path: 'account/list',
            element: hasSystemPermissions(PERMISSIONS.USE_PAGE_ADMIN_ACCOUNT) && <AccountList />,
          },
          {
            path: 'account/add',
            element: hasSystemPermissions(PERMISSIONS.USE_PAGE_ADMIN_ACCOUNT) && <AccountAdd />,
          },
          {
            path: 'account/detail',
            element: hasSystemPermissions(PERMISSIONS.USE_PAGE_ADMIN_ACCOUNT) && <AccountDetail />,
          },
          {
            path: 'template',
            element: <TemplateIndex />,
          },
        ],
      },
    ],
  };

  const placeRoutes: RouteObject = {
    path: '/place',
    element: <PlaceLayout />,
    children: [
      {
        index: true,
        element: <PlaceIndex />,
      },
    ],
  };

  const deviceRoutes: RouteObject = {
    path: '/device',
    element: <PlaceLayout />,
    children: [
      {
        index: true,
        element: <DeviceIndex />,
      },
      {
        path: 'detail',
        element: <DeviceDetail />,
      },
    ],
  };

  const storageRoutes: RouteObject = {
    path: '/storage',
    element: <PlaceLayout />,
    children: [
      {
        index: true,
        element: <StorageIndex />,
      },
    ],
  };

  const playlistRoutes: RouteObject = {
    path: '/playlist',
    element: <PlaceLayout />,
    children: [
      {
        index: true,
        element: <PlaylistIndex />,
      },
    ],
  };

  const editorRoutes: RouteObject = {
    path: '/editor',
    element: <EditorLayout />,
    children: [
      {
        index: true,
        element: <EditorIndex />,
      },
    ],
  };

  const systemRoutes: RouteObject = {
    path: '/system',
    element: <PlaceLayout />,
    children: [
      {
        path: 'setting',
        element: <SettingIndex />,
        children: [
          {
            path: 'place',
            element: hasPlacePermissions(PERMISSIONS.USE_PAGE_PLACE_SYSTEM_PLACE) && <PlaceDetail />,
          },
          {
            path: 'pack/list',
            element: hasPlacePermissions(PERMISSIONS.USE_PAGE_PLACE_SYSTEM_PACK) && <PackList />,
          },
          {
            path: 'pack/add',
            element: hasPlacePermissions(PERMISSIONS.USE_PAGE_PLACE_SYSTEM_PACK) && <PackAdd />,
          },
          {
            path: 'pack/detail',
            element: hasPlacePermissions(PERMISSIONS.USE_PAGE_PLACE_SYSTEM_PACK) && <PackDetail />,
          },
          {
            path: 'user/list',
            element: hasPlacePermissions(PERMISSIONS.USE_PAGE_PLACE_SYSTEM_USER) && <UserList />,
          },
          {
            path: 'user/add',
            element: hasPlacePermissions(PERMISSIONS.USE_PAGE_PLACE_SYSTEM_USER) && <UserAdd />,
          },
          {
            path: 'user/detail',
            element: hasPlacePermissions(PERMISSIONS.USE_PAGE_PLACE_SYSTEM_USER) && <UserDetail />,
          },
          {
            path: 'member',
            element: <MemberList />,
          },
        ],
      },
      {
        path: 'profile',
        element: <UserProfile />,
      },
    ],
  };

  const errorRoutes: RouteObject = {
    path: '/error',
    element: <ErrorLayout />,
    children: [
      {
        path: '401',
        element: <>401 Unauthorized</>,
      },
      {
        path: '403',
        element: <>403 Forbidden</>,
      },
    ],
  };

  const routes: RouteObject[] = [
    noMatchRoutes,
    authRoutes,
    adminRoutes,
    spaceRoutes,
    placeRoutes,
    deviceRoutes,
    storageRoutes,
    playlistRoutes,
    editorRoutes,
    systemRoutes,
    errorRoutes,
  ];

  return useRoutes(routes);
};

const Router = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Routes />
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default React.memo(Router);
