import { managerBaseApi } from '../managerBaseApi';

export const {
  endpoints,
  useGroupListQuery,
  useGroupListAllQuery,
  useGroupListByMemberQuery,
  useGroupAddMutation,
  useGroupUpdateMutation,
  useGroupEditMutation,
  useGroupRemoveMutation,
} = managerBaseApi.injectEndpoints({
  endpoints: build => ({
    groupList: build.query({
      query: data => ({ url: '/core/group/list', data }),
      transformResponse: response => {
        const groupList = response.groupList;
        const sortList = [...groupList];
        sortList.sort((lhs, rhs) => {
          if (lhs.groupNm?.toLowerCase() === rhs.groupNm?.toLowerCase()) {
            return 0;
          }
          return lhs.groupNm?.toLowerCase() > rhs.groupNm?.toLowerCase() ? 1 : -1;
        });
        return sortList;
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ groupId }) => ({ type: 'GROUP', id: groupId })),
        { type: 'GROUP', id: 'LIST' },
      ],
    }),
    groupListAll: build.query({
      query: data => ({ url: '/core/group/list-all', data }),
      transformResponse: response => {
        const groupList = response.groupList;
        return groupList;
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ groupId }) => ({ type: 'GROUP', id: groupId })),
        { type: 'GROUP', id: 'LIST' },
      ],
    }),
    groupListByMember: build.query({
      query: data => ({ url: '/core/group/list-by-member', data }),
      transformResponse: response => {
        const groupList = response.groupList;
        return groupList;
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ groupId }) => ({ type: 'GROUP', id: groupId })),
        { type: 'GROUP', id: 'LIST' },
      ],
    }),
    groupAdd: build.mutation({
      query: data => ({ url: '/core/group/add', data }),
      invalidatesTags: [{ type: 'GROUP', id: 'LIST' }],
    }),
    groupEdit: build.mutation({
      query: data => ({ url: '/core/group/edit', data }),
      invalidatesTags: (result, error, payload) => [{ type: 'GROUP', id: payload.groupId }],
    }),
    groupUpdate: build.mutation({
      query: data => ({ url: '/core/group/update', data }),
      invalidatesTags: [{ type: 'GROUP', id: 'LIST' }],
    }),
    groupRemove: build.mutation({
      query: data => ({ url: '/core/group/remove', data }),
      invalidatesTags: [{ type: 'GROUP', id: 'LIST' }],
    }),
    groupMove: build.mutation({
      query: data => ({ url: '/core/group/move', data }),
      invalidatesTags: [{ type: 'GROUP', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});
