import { createSlice, createDraftSafeSelector } from '@reduxjs/toolkit';
import { endpoints } from './authApi';

const initialState = {
  isLogin: false,
  loginInfo: {},
};

const reducers = {};

const extraReducers = builder => {
  builder.addMatcher(endpoints.login.matchFulfilled, (state, { payload }) => {
    state.isLogin = true;
    state.loginInfo = payload;
  });
  builder.addMatcher(endpoints.login.matchRejected, (state, { payload }) => {
    state.isLogin = false;
    state.loginInfo = {};
  });
  builder.addMatcher(endpoints.token.matchFulfilled, (state, { payload }) => {
    state.isLogin = true;
    state.loginInfo = payload;
  });
  builder.addMatcher(endpoints.token.matchRejected, (state, { payload }) => {
    state.isLogin = false;
    state.loginInfo = {};
  });
  builder.addMatcher(endpoints.logout.matchFulfilled, (state, { payload }) => {
    state.isLogin = false;
    state.loginInfo = {};
  });
};

const slice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: reducers,
  extraReducers: extraReducers,
});

const selectLoginInfo = createDraftSafeSelector(
  state => state.loginInfo,
  loginInfo => loginInfo,
);

const selectStatus = createDraftSafeSelector(
  state => state.isLogin,
  isLogin => ({ isLogin }),
);

export const authSelector = {
  loginInfo: state => selectLoginInfo(state[slice.name]),
  status: state => selectStatus(state[slice.name]),
};

export const AUTH = slice.name;
export const authReducer = slice.reducer;
export const authAction = slice.actions;
