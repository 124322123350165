import { createSlice, createDraftSafeSelector } from '@reduxjs/toolkit';
import { endpoints } from './placeApi';

const initialState = {
  placeId: '',
  placeList: [],
  placeInfo: {},
};

const reducers = {
  setState: (state, { payload: { key, value } }) => {
    state[key] = value;
  },
};

const extraReducers = builder => {
  builder.addMatcher(endpoints.placeList.matchFulfilled, (state, { payload }) => {
    state.placeList = payload;
  });
  builder.addMatcher(endpoints.placeDetail.matchFulfilled, (state, { payload }) => {
    state.placeInfo = payload;
  });
};

const slice = createSlice({
  name: 'place',
  initialState: initialState,
  reducers: reducers,
  extraReducers: extraReducers,
});

const selectPlaceId = createDraftSafeSelector(
  state => state.placeId,
  placeId => placeId,
);

const selectPlaceList = createDraftSafeSelector(
  state => state.placeList,
  placeList => placeList,
);

const selectPlaceInfo = createDraftSafeSelector(
  state => state.placeInfo,
  placeInfo => placeInfo,
);

export const placeSelector = {
  placeId: state => selectPlaceId(state[slice.name]),
  placeList: state => selectPlaceList(state[slice.name]),
  placeInfo: state => selectPlaceInfo(state[slice.name]),
};

export const PLACE = slice.name;
export const placeReducer = slice.reducer;
export const placeAction = slice.actions;
