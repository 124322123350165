import React, { Suspense } from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import HashLoader from 'react-spinners/HashLoader';
import { ToastContainer } from 'react-toastify';

import { useSelector } from 'react-redux';
import { authSelector } from 'rtk/core/authSlice';

import Token from 'pages/auth/Token';
import Header from 'pages/common/SpaceHeader';
import Footer from 'pages/common/Footer';

const contentLoading = () => (
  <HashLoader
    css={{
      display: 'block',
      width: '100%',
      height: '100%',
      margin: '0 auto',
    }}
    color={'#41a1ea'}
    loading={true}
    size={100}
  />
);

const SpaceLayout = props => {
  const status = useSelector(authSelector.status);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return !status.isLogin ? (
    <Token />
  ) : (
    <Container isMobile={isMobile}>
      <Content>
        <Header />
        <Suspense fallback={contentLoading()}>
          <Outlet />
        </Suspense>
        <Footer />
      </Content>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </Container>
  );
};

const Container = styled.div`
  min-width: 360px;
  min-height: 100%;
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default SpaceLayout;
