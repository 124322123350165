import { managerBaseApi } from '../managerBaseApi';

export const {
  endpoints,
  usePlaylistListQuery,
  usePlaylistListByPlaceQuery,
  usePlaylistDetailQuery,
  usePlaylistAddMutation,
  usePlaylistAddByTemplateMutation,
  usePlaylistUpdateMutation,
  usePlaylistCopyMutation,
  usePlaylistRemoveMutation,
  usePlaylistFolderListQuery,
  usePlaylistFolderDetailQuery,
  usePlaylistFolderAddMutation,
  usePlaylistFolderUpdateMutation,
  usePlaylistFolderRemoveMutation,
  usePlaylistPreviewTokenMutation,
} = managerBaseApi.injectEndpoints({
  endpoints: build => ({
    playlistList: build.query({
      query: data => ({ url: '/interaction/playlist/list', data }),
      transformResponse: response => {
        const playlistList = response.playlistList;
        const sortList = [...playlistList];
        sortList.sort((lhs, rhs) => {
          if (lhs.playlistNm?.toLowerCase() === rhs.playlistNm?.toLowerCase()) {
            return 0;
          }
          return lhs.playlistNm?.toLowerCase() > rhs.playlistNm?.toLowerCase() ? 1 : -1;
        });
        return sortList;
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ playlistId }) => ({ type: 'PLAYLIST', id: playlistId })),
        { type: 'PLAYLIST', id: 'LIST' },
      ],
      keepUnusedDataFor: 0,
    }),
    playlistListByPlace: build.query({
      query: data => ({ url: '/interaction/playlist/list-by-place', data }),
      transformResponse: response => {
        const playlistList = response.playlistList;
        const sortList = [...playlistList];
        sortList.sort((lhs, rhs) => {
          if (lhs.playlistId === rhs.playlistId) {
            return 0;
          }
          return lhs.playlistId > rhs.playlistId ? -1 : 1;
        });
        return sortList.slice(0, 4);
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ playlistId }) => ({ type: 'PLAYLIST', id: playlistId })),
        { type: 'PLAYLIST', id: 'LIST' },
      ],
      keepUnusedDataFor: 0,
    }),
    playlistDetail: build.query({
      query: data => ({ url: '/interaction/playlist/detail', data }),
      transformResponse: response => {
        const playlistInfo = response.playlistInfo;
        return playlistInfo;
      },
      providesTags: (result, error, payload) => [{ type: 'PLAYLIST', id: payload.playlistId }],
      keepUnusedDataFor: 0,
    }),
    playlistAdd: build.mutation({
      query: data => ({ url: '/interaction/playlist/add', data }),
      invalidatesTags: [{ type: 'PLAYLIST', id: 'LIST' }],
    }),
    playlistAddByTemplate: build.mutation({
      query: data => ({ url: '/interaction/playlist/add-by-template', data }),
      invalidatesTags: [{ type: 'PLAYLIST', id: 'LIST' }],
    }),
    playlistUpdate: build.mutation({
      query: data => ({ url: '/interaction/playlist/update', data }),
      invalidatesTags: (result, error, payload) => [{ type: 'PLAYLIST', id: payload.playlistId }],
    }),
    playlistCopy: build.mutation({
      query: data => ({ url: '/interaction/playlist/copy', data }),
      invalidatesTags: [{ type: 'PLAYLIST', id: 'LIST' }],
    }),
    playlistRemove: build.mutation({
      query: data => ({ url: '/interaction/playlist/remove', data }),
      invalidatesTags: [{ type: 'PLAYLIST', id: 'LIST' }],
    }),
    playlistFolderList: build.query({
      query: data => ({ url: '/interaction/playlist/folder-list', data }),
      transformResponse: response => {
        const folderList = response.folderList;
        const sortList = [...folderList];
        sortList.sort((lhs, rhs) => {
          if (lhs.folderNm?.toLowerCase() === rhs.folderNm?.toLowerCase()) {
            return 0;
          }
          return lhs.folderNm?.toLowerCase() > rhs.folderNm?.toLowerCase() ? 1 : -1;
        });
        return sortList;
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ folderId }) => ({ type: 'PLAYLIST-FOLDER', id: folderId })),
        { type: 'PLAYLIST-FOLDER', id: 'LIST' },
      ],
      keepUnusedDataFor: 0,
    }),
    playlistFolderDetail: build.query({
      query: data => ({ url: '/interaction/playlist/folder-detail', data }),
      transformResponse: response => {
        const folderInfo = response.folderInfo;
        return folderInfo;
      },
      providesTags: (result, error, payload) => [{ type: 'PLAYLIST-FOLDER', id: payload.folderId }],
      keepUnusedDataFor: 0,
    }),
    playlistFolderAdd: build.mutation({
      query: data => ({ url: '/interaction/playlist/folder-add', data }),
      invalidatesTags: [{ type: 'PLAYLIST-FOLDER', id: 'LIST' }],
    }),
    playlistFolderUpdate: build.mutation({
      query: data => ({ url: '/interaction/playlist/folder-update', data }),
      invalidatesTags: (result, error, payload) => [{ type: 'PLAYLIST-FOLDER', id: payload.folderId }],
    }),
    playlistFolderRemove: build.mutation({
      query: data => ({ url: '/interaction/playlist/folder-remove', data }),
      invalidatesTags: [{ type: 'PLAYLIST-FOLDER', id: 'LIST' }],
    }),
    playlistPreviewToken: build.mutation({
      query: data => ({ url: '/interaction/playlist/preview-token', data }),
      transformResponse: response => {
        return response.playToken;
      },
    }),
  }),
  overrideExisting: false,
});
