import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { configSelector, configAction } from 'rtk/core/configSlice';
import { authSelector } from 'rtk/core/authSlice';
import { placeSelector } from 'rtk/core/placeSlice';

import Select from 'components/SelectOption';

import LogoImg from 'assets/images/menu_logo.png';

import {
  FiMenu as MenuIcon,
  FiX as CloseIcon,
  FiMapPin as PlaceIcon,
  FiTablet as DeviceIcon,
  FiArchive as StorageIcon,
  FiFilm as PlaylistIcon,
  FiTv as ChannelIcon,
  FiSettings as SettingIcon,
  FiLogOut as LogOutIcon,
} from 'react-icons/fi';

const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const langCd = useSelector(configSelector.langCd);
  const loginInfo = useSelector(authSelector.loginInfo);
  const placeInfo = useSelector(placeSelector.placeInfo);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const pathname = location.pathname;

    if (pathname === '/') {
      document.title = 'I-Reception';
    } else if (pathname.indexOf('/place') === 0) {
      document.title = 'I-Reception - ' + t('navPlace');
    } else if (pathname.indexOf('/device') === 0) {
      document.title = 'I-Reception - ' + t('navDevice');
    } else if (pathname.indexOf('/storage') === 0) {
      document.title = 'I-Reception - ' + t('navStorage');
    } else if (pathname.indexOf('/playlist') === 0) {
      document.title = 'I-Reception - ' + t('navPlaylist');
    } else if (pathname.indexOf('/system') === 0) {
      document.title = 'I-Reception - ' + t('navSetting');
    } else {
      document.title = 'I-Reception';
    }
  }, [location, t]);

  const onMenuClick = useCallback(
    url => {
      setIsMenuOpen(false);
      navigate(url, { state: { placeId: placeInfo.placeId } });
    },
    [navigate, placeInfo],
  );

  const isMenuActive = useCallback(
    url => {
      return location.pathname.substring(0, url.length) === url;
    },
    [location],
  );

  const onProfile = useCallback(async () => {
    if (loginInfo.loginType === 'account') {
      navigate('/space');
    } else if (loginInfo.loginType === 'user') {
      navigate('/system/profile');
    }
  }, [loginInfo, navigate]);

  const onLogout = useCallback(async () => {
    navigate('/auth/logout');
  }, [navigate]);

  const changeLangCode = useCallback(
    langCd => {
      dispatch(configAction.changeLangCd(langCd));
    },
    [dispatch],
  );

  const MenuListContainer = () => {
    return (
      <>
        <MenuList>
          <LanguageSelect
            selectedValue={langCd}
            optionList={[
              { title: '한국어', value: 'ko' },
              { title: 'English', value: 'en' },
            ]}
            down
            onSelectChange={value => changeLangCode(value)}
          />
          <MenuItem onClick={e => onMenuClick('/place')} active={isMenuActive('/place')}>
            <PlaceIcon />
            <span>{t('navPlace')}</span>
          </MenuItem>
          <MenuItem onClick={e => onMenuClick('/device')} active={isMenuActive('/device')}>
            <DeviceIcon />
            <span>{t('navDevice')}</span>
          </MenuItem>
          <MenuItem onClick={e => onMenuClick('/storage')} active={isMenuActive('/storage')}>
            <StorageIcon />
            <span>{t('navStorage')}</span>
          </MenuItem>
          <MenuItem onClick={e => onMenuClick('/playlist')} active={isMenuActive('/playlist')}>
            <PlaylistIcon />
            <span>{t('navPlaylist')}</span>
          </MenuItem>
          <MenuItem onClick={e => onMenuClick('/system/setting/place')} active={isMenuActive('/system')}>
            <SettingIcon />
            <span>{t('navSetting')}</span>
          </MenuItem>
        </MenuList>
        <Version>AWS v1.0.1</Version>
      </>
    );
  };

  return (
    <>
      {isMobile ? (
        <MobileContainer>
          <MenuLogo onClick={e => onMenuClick('/place')}>
            <img src={LogoImg} alt="logo" />
          </MenuLogo>
          {isMenuOpen ? (
            <CloseIcon className="showBtn" onClick={e => setIsMenuOpen(!isMenuOpen)} />
          ) : (
            <MenuIcon className="showBtn" onClick={e => setIsMenuOpen(!isMenuOpen)} />
          )}
          {isMenuOpen && (
            <MobileMenu>
              <ProfileItem>
                <ProfileBtn onClick={e => onProfile()} title="프로필">
                  {loginInfo.loginType === 'account' ? loginInfo.accountEmail : loginInfo.accountNm}
                </ProfileBtn>
                <LogoutBtn onClick={e => onLogout()} title="로그아웃">
                  <LogOutIcon />
                </LogoutBtn>
              </ProfileItem>
              <MenuListContainer />
            </MobileMenu>
          )}
        </MobileContainer>
      ) : (
        <Container>
          <MenuLogo onClick={e => onMenuClick('/place')}>
            <img src={LogoImg} alt="logo" />
          </MenuLogo>
          <ProfileItem>
            <ProfileBtn onClick={e => onProfile()} title="프로필">
              {loginInfo.loginType === 'account'
                ? loginInfo.accountEmail
                : loginInfo.accountNm + ' (' + loginInfo.loginId + ')'}
            </ProfileBtn>
            <LogoutBtn onClick={e => onLogout()} title="로그아웃">
              <LogOutIcon />
            </LogoutBtn>
          </ProfileItem>
          <MenuListContainer />
        </Container>
      )}
    </>
  );
};

const MobileContainer = styled.div`
  height: 64px;
  width: 100%;
  min-width: 360px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background: #ffffff;
  z-index: 1001;

  .showBtn {
    width: 20px;
    height: 20px;
    color: #cccccc;

    &:hover {
      color: #60bbff;
    }
  }
`;

const MobileMenu = styled.div`
  width: 100%;
  height: calc(100vh - 64px);
  position: absolute;
  left: 0;
  top: 64px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1002;
`;

const Container = styled.div`
  height: 100%;
  width: 240px;
  position: fixed;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1001;
`;

const MenuLogo = styled.div`
  min-height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MenuList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-content: center;
`;

const MenuItem = styled.div`
  padding: 20px 35px;
  width: 100%;
  display: flex;
  align-items: center;
  color: ${props => (props.active ? '#1f6fff' : '#1a1a1a')};
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: #cccccc;
  }

  & > svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`;

const LanguageSelect = styled(Select)`
  padding: 10px 35px;
  width: 100%;
`;

const ProfileItem = styled.div`
  padding: 10px 35px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProfileBtn = styled.div`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #808080;
  cursor: pointer;

  &:hover {
    color: #2a91df;
  }
`;

const LogoutBtn = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #808080;
  cursor: pointer;

  &:hover {
    color: #2a91df;
  }
`;

const Version = styled.div`
  padding: 10px 35px;
  height: 36px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #1f1f1f;
  font-size: 12px;
  font-weight: 400;
`;

export default React.memo(NavBar);
