import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive';

const Component = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return (
    <Footer isMobile={isMobile}>
      <span>&copy; {dayjs().year()}, SQISoft Inc. All rights reserved.</span>
    </Footer>
  );
};

const Footer = styled.div`
  height: 40px;
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  justify-content: ${props => (props.isMobile ? 'center' : 'flex-end')};
  align-items: ${props => (props.isMobile ? 'flex-end' : 'center')};
  padding: ${props => (props.isMobile ? '0 16px' : '0 40px')};
  background-color: ${props => props.theme.bodyBg};
  color: #666666;
  font-weight: 400;
  font-size: 12px;
`;

export default React.memo(Component);
