import React, { Suspense } from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import HashLoader from 'react-spinners/HashLoader';
import { ToastContainer } from 'react-toastify';

import { useSelector } from 'react-redux';
import { authSelector } from 'rtk/core/authSlice';
import { usePlaceDetailQuery } from 'rtk/core/placeApi';

import Token from 'pages/auth/Token';
import NavBar from 'pages/common/NavBar';
import Footer from 'pages/common/Footer';

const loading = () => (
  <HashLoader
    css={{
      display: 'block',
      width: '100%',
      height: '100%',
      margin: '0 auto',
    }}
    color={'#41a1ea'}
    loading={true}
    size={20}
  />
);

const contentLoading = () => (
  <Loading>
    <HashLoader
      css={{
        display: 'block',
        width: '100%',
        height: '100%',
        margin: '0 auto',
      }}
      color={'#41a1ea'}
      loading={true}
      size={100}
    />
  </Loading>
);

const PlaceLayout = props => {
  const status = useSelector(authSelector.status);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  usePlaceDetailQuery(
    { placeId: sessionStorage.getItem('placeId') },
    { refetchOnMountOrArgChange: true, skip: !sessionStorage.getItem('placeId') },
  );

  return !status.isLogin ? (
    <Token />
  ) : (
    <Container isMobile={isMobile}>
      <Suspense fallback={loading()}>
        <NavBar />
      </Suspense>
      <Content isMobile={isMobile}>
        <Suspense fallback={contentLoading()}>
          <Outlet />
        </Suspense>
        <Footer />
      </Content>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </Container>
  );
};

const Container = styled.div`
  min-width: 360px;
  min-height: 100%;
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
`;

const Content = styled.div`
  width: 100%;
  margin-top: ${props => (props.isMobile ? '64px' : '0')};
  margin-left: ${props => (props.isMobile ? '0' : '240px')};
  display: flex;
  flex-direction: column;
`;

const Loading = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default PlaceLayout;
