import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationKo from './translation_ko.json';
import translationEn from './translation_en.json';

const resources = {
  ko: {
    translation: translationKo,
  },
  en: {
    translation: translationEn,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: i18n.options.lng,
  fallbackLng: localStorage.getItem('langCd') || 'ko',
  debug: true,
  supportedLngs: ['ko', 'en'],
  ns: ['translation'],
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
