import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';

const developmentUrl = 'https://faas.eligaws.com/space/manager';
const productionUrl = 'https://faas.eligaspace.com/space/manager';
const localUrl = 'http://localhost:8000/space/manager';

const axiosBaseQuery = async ({ url, method = 'POST', data }) => {
  try {
    const accessToken = localStorage.getItem('accessToken');

    const baseUrl = window.location.href.includes('eligaws.com')
      ? developmentUrl
      : window.location.href.includes('eligaspace.com')
      ? productionUrl
      : window.location.href.includes('eliga.co.kr')
      ? productionUrl
      : localUrl;

    const response = await axios({
      method: 'post',
      url: baseUrl + url,
      headers: {
        'X-Api-Key': 'bCAoIUYQ8N1CZ4rMDPdvJ54HqeZwfgeF96NCYJhQ',
        'content-type': 'application/json',
      },
      data: { ...data, accessToken },
    }).catch(err => {
      if (err.response.status === 401) {
        window.location.href = '/auth/login';
      } else {
        throw err;
      }
    });

    if (response.status === 200) {
      return { data: response.data };
    } else {
      throw Object.assign(new Error('api error'), {
        status: response.status,
      });
    }
  } catch (err) {
    throw err;
  }
};

export const managerBaseApi = createApi({
  reducerPath: 'managerBaseApi',
  baseQuery: axiosBaseQuery,
  endpoints: () => ({}),
});

export const fetchUploadApi = async (url, file, onUploadProgress, index) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      if (xhr.status < 400) {
        resolve(true);
      } else {
        const error = new Error(xhr.response);
        reject(error);
      }
    };
    xhr.upload.onprogress = evt => {
      if (evt.lengthComputable) {
        onUploadProgress && onUploadProgress(file, evt.loaded, evt.total, index);
      }
    };
    xhr.onerror = error => {
      reject(error);
    };
    xhr.onreadystatechange = evt => {
      if (xhr.readyState === 1) {
      } else if (xhr.readyState === 4) {
      }
    };

    xhr.open('PUT', url, true);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);
  });
};

export const fetchMultipartApi = async (url, data, options = {}) => {
  try {
    const accessToken = localStorage.getItem('accessToken');

    const baseUrl = window.location.href.includes('eligaws.com')
      ? developmentUrl
      : window.location.href.includes('eligaspace.com')
      ? productionUrl
      : window.location.href.includes('eliga.co.kr')
      ? productionUrl
      : localUrl;

    const response = await axios({
      method: 'post',
      url: baseUrl + url + `?accessToken=${accessToken}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...options.headers,
      },
      data: data,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw Object.assign(new Error('api error'), {
        status: response.status,
      });
    }
  } catch (err) {
    throw err;
  }
};
