import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { authSelector } from 'rtk/core/authSlice';
import { useHasPermissions, PERMISSIONS } from 'hooks/useHasPermissions';

import LogoImg from 'assets/images/header_logo.png';
import { FiLogOut as LogOutIcon } from 'react-icons/fi';

const Header = props => {
  const navigate = useNavigate();
  const loginInfo = useSelector(authSelector.loginInfo);
  const { hasSystemPermissions } = useHasPermissions();

  const onHome = useCallback(async () => {
    navigate('/space');
  }, [navigate]);

  const onProfile = useCallback(async () => {
    navigate('/space/profile');
  }, [navigate]);

  const onAdmin = useCallback(async () => {
    navigate('/admin/space/list');
  }, [navigate]);

  const onLogout = useCallback(async () => {
    navigate('/auth/logout');
  }, [navigate]);

  return (
    <Container>
      <LeftSide>
        <HeaderLogo onClick={e => onHome()}>
          <img src={LogoImg} alt="logo" />
        </HeaderLogo>
      </LeftSide>
      <RightSide>
        <ProfileLogo>
          {loginInfo.imagePath ? (
            <img src={`${process.env.REACT_APP_CORE_CDN_URL}${loginInfo.imagePath}`} alt="" />
          ) : (
            <span>{loginInfo.accountEmail?.substring(0, 2).toUpperCase()}</span>
          )}
        </ProfileLogo>
        <ProfileBtn onClick={e => onProfile()}>
          {loginInfo.accountEmail} ({loginInfo.accountNm})
        </ProfileBtn>
        {hasSystemPermissions(PERMISSIONS.USE_PAGE_ADMIN) && <AdminBtn onClick={e => onAdmin()}>관리자</AdminBtn>}
        <LogoutBtn onClick={e => onLogout()} title="로그아웃">
          <LogOutIcon />
        </LogoutBtn>
      </RightSide>
    </Container>
  );
};

const Container = styled.div`
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const LeftSide = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const RightSide = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

const AdminBtn = styled.div`
  width: 60px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999999;
  border-radius: 10px;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    border: 1px solid #2a91df;
    color: #2a91df;
  }
`;

const ProfileLogo = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  border: 1px solid #999999;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 6px;

  & > img {
    position: absolute;
    max-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & > span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
    color: #333333;
  }
`;

const ProfileBtn = styled.div`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: #2a91df;
  }
`;

const LogoutBtn = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: #2a91df;
  }
`;

const HeaderLogo = styled.div`
  width: 240px;
  min-height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default React.memo(Header);
